import React from 'react';
import Transaction from '../Transaction';

const PublicTransaction = (props) => {
  return (
    <div className="transaction-body">
      <Transaction {...props} />
    </div>
  );
};

export default PublicTransaction;
