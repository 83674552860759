import React, { useEffect, useState } from 'react';
import SearchableDropdown from '../SearchableDropdown';
import { getOfferingProducts } from 'utils/api.service';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import LoadingSpinner from 'components/LoadingSpinner';

const OfferSchema = Yup.object().shape({
  offeringId: Yup.string().required('Offering ID is required'),
  quantity: Yup.number()
    .required('Quantity is required')
    .min(1, 'Quantity must be at least 1'),
  term: Yup.string().required('Term is required'),
});

const OfferingForm = ({ env, usageType, onLineItemsUpdated }) => {
  const [options, setOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const [selectedOfferingId, setSelectedOfferingId] = useState(null);
  const [offers, setOffers] = useState([]);
  const [addedOfferings, setAddedOfferings] = useState([]);
  const [clearInputValue, setClearInputValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addOffer = (values, { resetForm }) => {
    setOffers([...offers, values]);
    const offeringName = options.find(
      (option) => option.value === values.offeringId
    ).name;

    const termDescription = termOptions
      .find((term) => term.offeringId === selectedOfferingId)
      ?.offers.find((offer) => offer.code === values.term).description;

    const addedOffering = {
      offeringId: values.offeringId,
      quantity: values.quantity,
      offeringName,
      termDescription,
      termCode: values.term,
    };

    setAddedOfferings([...addedOfferings, addedOffering]);
    resetForm();
    setClearInputValue(true);
    setSelectedOfferingId(null);
    onLineItemsUpdated([...addedOfferings, addedOffering]);
  };

  const removeOffer = (index) => {
    setAddedOfferings(addedOfferings.filter((_, i) => i !== index));
    onLineItemsUpdated(addedOfferings.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const fetchOfferingProducts = async () => {
      try {
        setIsLoading(true);
        const response = await getOfferingProducts(env, usageType);
        const formattedOptions = response.data.results.map((offering) => ({
          value: offering.offeringId,
          name: offering.offeringName,
          sku: offering.offeringId,
        }));
        const termsOptions = response.data.results.map((offering) => ({
          offeringId: offering.offeringId,
          offers: offering.offers,
        }));
        setOptions(formattedOptions);
        setTermOptions(termsOptions);
      } catch (error) {
        // notify user of error
        console.error('Error fetching offers:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOfferingProducts();
  }, []);

  if (isLoading) {
    return (
      <div className="col-sm-12">
        <LoadingSpinner text="Loading products" />
      </div>
    );
  }

  return (
    <div className="col-sm-12">
      <div className="row">
        <Formik
          initialValues={{ offeringId: '', quantity: 1, term: '' }}
          validationSchema={OfferSchema}
          onSubmit={addOffer}
        >
          {({ setFieldValue, errors, values }) => (
            <Form className="offerings-form">
              <div className="searchable-dropdown-wrapper">
                <div className="col-sm-6">
                  <label>Offering</label>
                  <SearchableDropdown
                    options={options}
                    handleInputValue={(value) => {
                      setSelectedOfferingId(value);
                      setFieldValue('offeringId', value);
                      setFieldValue(
                        'term',
                        termOptions.find((term) => term.offeringId === value)
                          ?.offers[0].code
                      );
                    }}
                    clearValue={clearInputValue}
                    updateClearValue={setClearInputValue}
                    placeholder="Select an offering"
                  />
                </div>
                <div className="col-sm-2">
                  <label htmlFor="term">Term</label>
                  <Field as="select" name="term" className="form-control">
                    {termOptions
                      .find((term) => term.offeringId === selectedOfferingId)
                      ?.offers.map((offer) => (
                        <option key={offer.code} value={offer.code}>
                          {offer.description}
                        </option>
                      ))}
                  </Field>
                </div>
                <div className="col-sm-2">
                  <label htmlFor="quantity">Quantity</label>
                  <Field
                    type="number"
                    name="quantity"
                    className="form-control"
                  />
                </div>
                <div className="col-sm-2">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!selectedOfferingId}
                  >
                    Add
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="col-sm-12">
          {addedOfferings.map((offer, index) => (
            <div
              key={`${offer.offeringId}-${index}`}
              className="multiple-skus-lineitem-tag"
            >
              <span className="lineitem-tag-sku">
                {offer.offeringName} ({offer.quantity}) -{' '}
                {offer.termDescription}
              </span>
              <button
                type="button"
                className="lineitem-tag-remove"
                onClick={() => removeOffer(index)}
              >
                x
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfferingForm;
