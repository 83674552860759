import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import Login from '../Login';
import PrivateRoute from './CustomRoutes/PrivateRoute';
import PublicRoute from './CustomRoutes/PublicRoute';
import PublicTransaction from 'containers/PublicTransaction';
import { Route } from 'react-router-dom';

import resolveLayout from 'routes/layouts/resolveLayout';

const App = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <BrowserRouter>
      <Switch>
        {!isLoggedIn ? (
          <Route
            key={'transaction'}
            path={'/view/:service/:executionid'}
            component={PublicTransaction}
            exact={true}
          />
        ) : null}
        <PublicRoute exact path="/auth" component={Login} />
        <PrivateRoute path="/" component={resolveLayout} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
